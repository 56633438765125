import clsx from "clsx";
import React, { useMemo, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";

import { make_uid, random_name, update_query } from "../behaviors/query";
import { Region, regions } from "../behaviors/region";
import { Whiteboard } from "./Whiteboard";

export function Room() {
  const uuid = useParams<"uuid">().uuid;
  const [query] = useSearchParams();
  const roomToken = query.get("token");
  const region = query.get("region");
  const nickName = useMemo(() => query.get("tempName") || query.get("nickName") || random_name(), []);
  const useNew = useMemo(() => query.get("useNew") || "false", []);
  const uid = useMemo(() => query.get("uid") || make_uid(), []);

  const messages = validate(uuid, region);
  if (messages.length) {
    return (
      <ul className="room-error">
        {messages.map((message) => (
          <li key={message}>{message}</li>
        ))}
      </ul>
    );
  }

  return (
    <div className="room">
      <Whiteboard
        uid={uid}
        nickName={nickName}
        uuid={uuid as string}
        region={region as Region}
        roomToken={roomToken}
        useNew={useNew === "true"}
      />
      <ShareButton nickName={nickName} uid={uid} />
    </div>
  );
}

interface UserInfo {
  nickName: string;
  uid: string;
}

function ShareButton({ nickName, uid }: UserInfo) {
  const [actions, setActions] = useState(true);
  const [locked, setLocked] = useState(() => location.search.includes("uid"));

  function share() {
    const url = new URL(location.href);
    url.searchParams.delete("uid");
    url.searchParams.delete("roomToken");
    url.searchParams.delete("nickName");
    copyToClipboard(url.href);
  }

  function toggle_query(ev: React.MouseEvent) {
    if (ev.shiftKey) {
      ev.preventDefault();
      if (locked) {
        update_query({ uid: undefined, nickName: undefined });
        setLocked(false);
      } else {
        update_query({ uid, nickName });
        setLocked(true);
      }
    }
    // Toggle hiding share and home button
    else {
      setActions((e) => !e);
    }
  }

  return (
    <div className={clsx("top-right", { actions })}>
      <span className={clsx("info-panel", { locked })} title="toggle buttons &rarr;" onClick={toggle_query}>
        <strong>{nickName}</strong>
        <code>{uid}</code>
      </span>
      <button className="btn btn-share" title="Copy Shareable URL" onClick={share}>
        <i className="i-mdi-share-variant"></i>
      </button>
      <Link to="/" className="btn btn-home" title="Return Home Page">
        <i className="i-mdi-home"></i>
      </Link>
    </div>
  );
}

function copyToClipboard(str: string) {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(str).catch(() => copyToClipboardLegacy(str));
  } else {
    copyToClipboardLegacy(str);
  }
  alert("Share URL copied to clipboard.");
}

function copyToClipboardLegacy(str: string) {
  const input = document.createElement("input");
  input.value = str;
  document.body.appendChild(input);
  input.select();
  document.execCommand("copy");
  document.body.removeChild(input);
}

function validate(uuid: string | undefined, region: string | null) {
  const messages: string[] = [];
  if (!uuid) messages.push("Missing room uuid.");
  if (!region) messages.push("Missing region.");
  else if (!validateRegion(region)) messages.push("Invalid region.");
  return messages;
}

function validateRegion(region: string): region is Region {
  return regions.some((e) => e.region === region);
}
