import clsx from "clsx";
import React, { useCallback, useEffect, useRef, useState, useSyncExternalStore } from "react";

import { joinRoom } from "../behaviors/api";
import { FastboardModule, fastboardPromise } from "../behaviors/fastboard";
import { update_query } from "../behaviors/query";
import { Region } from "../behaviors/region";
import { useSafePromise } from "../helpers/use-safe-promise";
import { Loading } from "./Loading";
import { useTheme } from "../helpers/use-theme";
import { config as config_, config$ } from "../behaviors/config";

import LittleBoard from "@netless/app-little-white-board";
import { AutoDrawPlugin } from '@netless/appliance-plugin';
import fullWorkerString from '@netless/appliance-plugin/dist/fullWorker.js?raw';
import subWorkerString from '@netless/appliance-plugin/dist/subWorker.js?raw';
import { apps, register, RoomState } from "@netless/fastboard-react";
const fullWorkerBlob = new Blob([fullWorkerString], {type: 'text/javascript'});
const fullWorkerUrl = URL.createObjectURL(fullWorkerBlob);
const subWorkerBlob = new Blob([subWorkerString], {type: 'text/javascript'});
const subWorkerUrl = URL.createObjectURL(subWorkerBlob);

export interface WhiteboardProps {
  uid: string;
  nickName: string;
  uuid: string;
  region: Region;
  // if exist, use it (directly created by user)
  // if not exist, ask server for one (via share url)
  roomToken: string | null;
  useNew: boolean;
}

function fetchRoomToken(uuid: string, region: Region): Promise<string> {
  return joinRoom(uuid, region).then((r) => r.roomToken);
}

export function Whiteboard({ uid, nickName, uuid, roomToken: roomToken_, region, useNew }: WhiteboardProps) {
  const sp = useSafePromise();
  const [fastboard, setFastboard] = useState<FastboardModule | null>(null);
  const [roomToken, setRoomToken] = useState(roomToken_);
  const [loading, setLoading] = useState(true);

  const onReady = useCallback(() => setLoading(false), []);

  useEffect(() => {
    if (!fastboard) {
      sp(fastboardPromise).then(setFastboard);
    }
  }, []);

  useEffect(() => {
    if (roomToken) {
      // hide room token from address bar
      update_query({ token: undefined, tempName: undefined });
    } else {
      // fetch room token from server
      sp(fetchRoomToken(uuid, region)).then(setRoomToken);
    }
  }, [roomToken]);

  return (
    <>
      {(!fastboard || !roomToken || loading) && (
        <Loading>
          <div className="loading-panel">
            <div className="loading-module">Loading fastboard&hellip; {fastboard ? "done" : ""}</div>
            <div className="loading-token">Fetching room token&hellip; {roomToken ? "done" : ""}</div>
            <div className="loading-room">Connecting to room&hellip;</div>
          </div>
        </Loading>
      )}
      {fastboard && roomToken && (
        <div className="whiteboard">
          <FastboardWrapper
            uid={uid}
            nickName={nickName}
            fastboard={fastboard}
            uuid={uuid}
            roomToken={roomToken}
            region={region}
            onReady={onReady}
            useNew={useNew}
          />
        </div>
      )}
    </>
  );
}

type WrapperProps = WhiteboardProps & {
  roomToken: string;
  fastboard: FastboardModule;
  onReady: () => void;
};

function FastboardWrapper({ fastboard, onReady, ...props }: WrapperProps) {
  const [theme] = useTheme();
  const config = useSyncExternalStore(config$.subscribe, () => config_);
  const { Fastboard, useFastboard } = fastboard;

  const app = useFastboard(() => ({
    sdkConfig: {
      appIdentifier: import.meta.env.VITE_APPIDENTIFIER,
      region: props.region,
      // renderEngine: (get_render_engine() === "svg" ? "svg" : "canvas"),
    },
    managerConfig: {
      cursor: true,
      enableAppliancePlugin: props.useNew
    },
    joinRoom: {
      uid: props.uid,
      uuid: props.uuid,
      roomToken: props.roomToken,
      floatBar: true,
      userPayload: {
        nickName: props.nickName,
      },
    },
    enableAppliancePlugin: props.useNew ? {
      cdn: {
          fullWorkerUrl,
          subWorkerUrl,
      },
      strokeWidth: {
        min: 1,
        max: 32
      }
    } : undefined
  }));

  useEffect(() => {
    if (app) {
      if (props.useNew) {
        const isWritable = app.writable.value;
        register({
          kind: "LittleBoard",
          src: LittleBoard,
          appOptions: {
              language: 'zh-CN',
              disableCameraTransform: true,
              // 可选, 发布问题
              onMount:(appId:string, userId:string)=>{
                  console.log('LittleBoard Mount', appId, userId);
                  !isWritable && manager?.setReadonly(true);
              },
              /** 上传图片,返回插入图片的信息 */
              async onClickImage(){
                  // 弹出云盘,模拟1s之后放回图片信息
                  return new Promise((resolve) => {
                      setTimeout(()=>{
                          resolve({
                              uuid: Date.now().toString(),
                              src: `https://flat-storage-sg.oss-accelerate.aliyuncs.com/fastboard/${(Math.round(Math.random() * 3))}.webp`,
                              centerX: 0,
                              centerY: 0,
                              width: 100,
                              height: 100,
                              uniformScale: false
                          });
                      }, 1000)
                  });
              }
          }
        });
        apps.push({
          icon: "https://api.iconify.design/mdi:clipboard-edit-outline.svg?color=%237f7f7f",
          kind: 'LittleBoard',
          label: 'LittleBoard',
          async onClick(app) {
            app.manager.addApp({
              kind: 'LittleBoard',
              options: { 
                  title: "LittleBoard",
                  scenePath: '/LittleBoard'
              },
              attributes: {
                  uid: isWritable && app.room.uid,
              }
            })
          },
        });
      }
      const fastboard = app;
      const { sdk, room, manager, syncedStore } = fastboard;
      Object.assign(window, { fastboard, sdk, room, manager, syncedStore });

      const stop_listen_phase = app.phase.subscribe((phase) => {
        if (phase === "connected") {
          onReady();
        }
      });

      return () => {
        stop_listen_phase();
        Object.assign(window, {
          fastboard: null,
          sdk: null,
          room: null,
          manager: null,
          syncedStore: null,
        });
      };
    }
  }, [app]);

  const [tips, showTips] = useState(false);

  const onDragOver = useCallback(
    (ev: React.DragEvent<HTMLDivElement>) => {
      ev.preventDefault();
      ev.dataTransfer.dropEffect = "copy";
      showTips(true);
    },
    [app]
  );

  const onDragLeave = useCallback(() => {
    showTips(false);
  }, []);

  const onDrop = useCallback(
    (ev: React.DragEvent<HTMLDivElement>) => {
      ev.preventDefault();
      const file = ev.dataTransfer.files[0];
      if (app && file && file.name.endsWith(".scene")) {
        console.log("import scene", file.name);
        app.room
          .importScene("/", file)
          .then(() => app.syncedStore.nextFrame())
          .then(async (_scene) => {
            let nextSceneName = file.name.slice("whiteboard-".length, -".scene".length);

            // FIXME: wait white-web-sdk next version (> 2.16.47) to fix it
            // nextSceneName = _scene.name;
            const buffer = await file.arrayBuffer();
            const view = new DataView(buffer);
            if (view.getUint32(0, true) === 0x6f6b6b61) {
              const bare = buffer.slice(8 + view.getUint32(4, true));
              const blob = new Blob([bare]);
              const scene = await (app.room as any).getSceneFromBlob(blob);
              if (scene && scene.name) {
                nextSceneName = scene.name;
              }
            }

            console.log("import scene success ->", nextSceneName);
            const index = app.manager.sceneState.scenes.findIndex((e) => e.name === nextSceneName);
            if (index != null && index >= 0) {
              // change current scene without refreshing -- which is impossible
              // so create a dummy scene and switch it back
              if (app.sceneIndex.value === index) {
                await app.addPage({ after: true });
                await app.nextPage();
                await app.removePage();
              }
              app.sceneIndex.set(index);
            }
          })
          .catch((error) => {
            console.error("import scene failed");
            console.error(error);
            alert("" + error);
          })
          .finally(() => {
            showTips(false);
          });
      }
    },
    [app]
  );

  return (
    <div
      style={{ width: "100%", height: "100%", position: "relative" }}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
    >
      <Fastboard
        app={app}
        language={props.region.startsWith("cn") ? "zh-CN" : "en"}
        theme={theme}
        config={config}
      />
      <div className={clsx("tips", tips && "show")}>Importing&hellip;</div>
      {
        app?.appliancePlugin && app?.writable.value && <MiniMapView appliancePlugin={app.appliancePlugin}/> || null
      }
      {
        app?.appliancePlugin && app?.writable.value && app?.room && <UserFilterView appliancePlugin={app.appliancePlugin} room={app.room}/> || null
      }
      {
        app?.appliancePlugin && app?.writable.value && app?.room && <AutoDrawView appliancePlugin={app.appliancePlugin} room={app.room}/> || null
      }
    </div>
  );
}

const MiniMapView = (props:{
  appliancePlugin: any;
}) => {
  const {appliancePlugin} = props;
  const miniMapViewRef = useRef<HTMLDivElement>(null);
  const [actions, setActions] = useState(false);
  const [theme] = useTheme();
  const show = () => {
    setActions(!actions);
  }
  useEffect(() => {
    if (miniMapViewRef.current) {
      const div = miniMapViewRef.current;
      if (actions) {
        appliancePlugin.createMiniMap('mainView', div)
      } else {
        appliancePlugin.destroyMiniMap('mainView')
      }
    }
  }, [actions]);

  return (
    <div className={clsx("top-left", { actions })}>
      <div style={{
        position: "absolute",
        top: 30,
        width: 320,
        height: 180,
        marginLeft: -54,
        backgroundColor: theme === 'dark' ? '#333' : '#fff',
        boxShadow: '0px 3px 10px rgba(0,0,0,0.2)',
        display: actions ? 'block' : 'none',
      }} ref={miniMapViewRef}/>
      <button className="btn btn-map" title="mini map" onClick={show}>
        <i className="i-mdi-map-outline"></i>
      </button>
    </div>
  )
}

const UserFilterView = (props:{
  appliancePlugin: any;
  room: any;
}) => {
  const {appliancePlugin, room} = props;
  const [actions, setActions] = useState(false);
  const [users, setUsers] = useState<Array<{
    uid: string;
    nickName: string;
    color: string;
  }>>([]);
  const [renderUids, setRenderUids] = useState<string[] | true>(true);
  const show = () => {
    setActions(!actions);
  }
  const rgbToHex = (r: number, g: number, b: number) => {
    const hex = ((r << 16) + (g << 8) + b).toString(16).padStart(6, "0");
    return "#" + hex;
  }
  useEffect(()=>{
    appliancePlugin.addListener('syncRenderUids', listenerCallbacks);
    room.callbacks.on('onRoomStateChanged', roomStateChangeListener);
    return ()=>{
      appliancePlugin.removeListener('syncRenderUids', listenerCallbacks);
      room.callbacks.off('onRoomStateChanged', roomStateChangeListener);
    }
  },[])

  const roomStateChangeListener = (state: RoomState) => {
    if (state?.roomMembers) {
      const users = state.roomMembers.map((user:any) => ({
        uid: user.payload.uid,
        nickName: user.payload.nickName,
        color: user.memberState.strokeColor && rgbToHex(user.memberState.strokeColor[0], user.memberState.strokeColor[1], user.memberState.strokeColor[2]),
      }));
      setUsers(users);
    }
  }

  const listenerCallbacks = (viewId:string, syncData?:{render?:Set<string>|true, hide?:Set<string>|true, clear?:Set<string>|true}) => {
    if(viewId === 'mainView') {
      if (!syncData) {
        setRenderUids(true);
        return;
      }
      const {render} = syncData;
      if (render === true || !render ) {
        setRenderUids(true);
      } else {
        setRenderUids([...render]);
      }
    }
  }

  useEffect(() => {
    if (actions) {
      const syncData = appliancePlugin.currentManager.renderControl.renderUids.get('mainView');
      listenerCallbacks('mainView', syncData)
      roomStateChangeListener(room.state);
    } else {
      setUsers([]);
      setRenderUids([]);
    }
  }, [actions]);

  const filter = (uid: string | true) => {
    let _renderUids:string[] | true = [];
    if (uid === true) {
      _renderUids = true;
    } else if (renderUids === true) {
      _renderUids = [uid];
    } else if (renderUids.includes(uid)) {
      _renderUids = renderUids.filter((e) => e !== uid);
    } else {
      _renderUids = [...renderUids, uid]
    }
    setRenderUids(_renderUids);
    appliancePlugin.filterRenderByUid('mainView',  { render: _renderUids, clear: _renderUids }, true);
  }

  return (
    <div className={clsx("top-left1", { actions })}>
      {
        users.length > 0 && <div className="users" style={{
          position: "absolute",
          top: 30,
          width: 320,
          display: actions ? 'flex' : 'none',
        }}>
          <button key={0} className="btn btn-user" title={'all users'} onClick={()=>{filter(true)}}>
            <i className={renderUids === true ? "i-mdi-users-check-outline" : "i-mdi-users-outline"}></i>
          </button>
          {
            users.map((user) => {
              const {uid, nickName, color} = user
              return (
                <button key={uid} className="btn btn-user" title={nickName} onClick={()=>{filter(uid)}}>
                  <i style={{color: color}} className={renderUids !== true && renderUids.includes(uid) ? "i-mdi-user-check-outline" : "i-mdi-user-outline"}></i>
                </button>
              )
            })
          }
        </div>
      }
      <button className="btn btn-map" title="user filter" onClick={show}>
        <i className="i-mdi-users-outline"></i>
      </button>
    </div>
  )
}

const AutoDrawView = (props:{
  appliancePlugin: any;
  room: any;
}) => {
  const {appliancePlugin, room} = props;
  const topBarViewRef = useRef<HTMLDivElement>(null);
  const [actions, setActions] = useState(false);
  const [autoDrawPlugin, setAutoDrawPlugin] = useState<AutoDrawPlugin|null>(null);
  const [color, setColor] = useState<string>('#000000');
  const show = () => {
    setActions(!actions);
  }
  const rgbToHex = (r: number, g: number, b: number) => {
    const hex = ((r << 16) + (g << 8) + b).toString(16).padStart(6, "0");
    return "#" + hex;
  }
  const roomStateChangeListener = (state: RoomState) => {
    if (state?.memberState) {
      setColor(rgbToHex(state.memberState.strokeColor[0], state.memberState.strokeColor[1], state.memberState.strokeColor[2]));
    }
  }
  useEffect(() => {
    const div = topBarViewRef?.current;
    if (div) {
      const _autoDrawPlugin = new AutoDrawPlugin({
        container: div,
        hostServer: 'https://autodraw-white-backup-hk-hkxykbfofr.cn-hongkong.fcapp.run',
        delay: 2000
      });
      appliancePlugin.usePlugin(_autoDrawPlugin);
      setAutoDrawPlugin(_autoDrawPlugin);
    }
    room.callbacks.on('onRoomStateChanged', roomStateChangeListener);
    roomStateChangeListener(room.state);
    return () => {
      autoDrawPlugin?.unMount();
      room.callbacks.off('onRoomStateChanged', roomStateChangeListener);
    }
  }, [])

  useEffect(() => {
    if (actions) {
      autoDrawPlugin?.mount();
    } else {
      autoDrawPlugin?.unMount();
    }
  }, [actions]);

  return (
    <div className={clsx("top-left2", { actions })}>
      <div style={{
        position: "fixed",
        top: 30,
        left: 0,
        width: '100vw',
        height: 50,
        zIndex: 10000,
        display: actions ? 'block' : 'none',
        pointerEvents: 'all'
      }} ref={topBarViewRef}/>
      <button className="btn" title="auto draw" onClick={show}>
        <i style={{color: actions && color || 'inherit'}} className="i-mdi-auto-fix"></i>
      </button>
    </div>
  )
}