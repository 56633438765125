// import Countdown from "@netless/app-countdown";
// import GeoGebra from "@netless/app-geogebra";
import EmbeddedPage, { type AppOptions as EmbeddedPageOptions } from "@netless/app-embedded-page";
export type FastboardModule = typeof import("@netless/fastboard-react");
// import { NetlessAppQuill } from "@netless/app-quill";
import { install } from "@netless/app-presentation"
let fastboard: FastboardModule | undefined;

export async function importFastboard(): Promise<FastboardModule> {
  if (!fastboard) {
    fastboard = await import("@netless/fastboard-react") as unknown as FastboardModule;
    initialize(fastboard);
  }
  return fastboard;
}

export const fastboardPromise = importFastboard();

fastboardPromise.then((Fastboard) => Object.assign(window, { Fastboard }));

function initialize(fastboard: FastboardModule) {
  // Add extra buttons to the last panel in toolbar.
  const { apps, register } = fastboard;

  // register newer Monaco Editor
  // register({
  //   kind: "Monaco",
  //   src: "https://netless-app.oss-cn-hangzhou.aliyuncs.com/@netless/app-monaco/0.1.14-beta.1/dist/main.iife.js",
  // });

  // register newer GeoGebra
  // register({
  //   kind: "GeoGebra",
  //   src: GeoGebra,
  // });
  const geogebra_def = apps.data.find((e) => e.kind === "GeoGebra");
  if (geogebra_def) {
    geogebra_def.onClick = function (app) {
      app.manager.addApp({
        kind: "GeoGebra",
        options: { title: "GeoGebra" },
        attributes: { appName: "suite" },
      });
    };
  }

  // register newer Countdown
  // register({
  //   kind: "Countdown",
  //   src: Countdown,
  // });

  // register newer EmbeddedPage
  register({
    kind: "EmbeddedPage",
    src: EmbeddedPage,
    appOptions: {
      setupIframe(iframe) {
        iframe.setAttribute(
          "sandbox",
          // all but allow-top-navigation
          [
            "allow-downloads",
            "allow-forms",
            "allow-modals",
            "allow-orientation-lock",
            "allow-pointer-lock",
            "allow-popups",
            "allow-popups-to-escape-sandbox",
            "allow-presentation",
            "allow-same-origin",
            "allow-scripts",
          ].join(" ")
        );
      },
    } satisfies EmbeddedPageOptions,
  });

  install(register, { as: 'DocsViewer'});
  apps.push({
    icon: "https://api.iconify.design/mdi:file-word-box.svg?color=%237f7f7f",
    kind: "Presentation",
    label: "Docs",
    onClick: (app) => {
      app.insertDocs({
        fileType: "pdf",
        scenePath: `/pdf/18140800fe8a11eb8cb787b1c376634e`,
        title: "a.pdf",
        scenes: [
          {
            name: "a.pdf - 第 1 页",
            ppt: {
              height: 1010,
              src: "https://convertcdn.netless.link/staticConvert/18140800fe8a11eb8cb787b1c376634e/1.png",
              width: 714,
            },
          },
          {
            name: "a.pdf - 第 2 页",
            ppt: {
              height: 1010,
              src: "https://convertcdn.netless.link/staticConvert/18140800fe8a11eb8cb787b1c376634e/2.png",
              width: 714,
            },
          },
        ],
      });
    },
  });

  // DocsViewer (static mode)
  // Note: use our slide conversion service to obtain the task id
  // https://developer.netless.link/server-en/home/server-conversion
  // apps.push({
  //   icon: "https://api.iconify.design/mdi:file-word-box.svg?color=%237f7f7f",
  //   kind: "DocsViewer",
  //   label: "Docs",
  //   onClick: (app) => {
  //     app.insertDocs({
  //       fileType: "pdf",
  //       scenePath: `/pdf/18140800fe8a11eb8cb787b1c376634e`,
  //       title: "a.pdf",
  //       scenes: [
  //         {
  //           name: "1",
  //           ppt: {
  //             height: 1010,
  //             src: "https://convertcdn.netless.link/staticConvert/18140800fe8a11eb8cb787b1c376634e/1.png",
  //             width: 714,
  //           },
  //         },
  //         {
  //           name: "2",
  //           ppt: {
  //             height: 1010,
  //             src: "https://convertcdn.netless.link/staticConvert/18140800fe8a11eb8cb787b1c376634e/2.png",
  //             width: 714,
  //           },
  //         },
  //       ],
  //     });
  //   },
  // });

  // Slide
  // Note: use our slide conversion service to obtain the task id
  // https://developer.netless.link/server-en/home/server-conversion
  apps.push({
    icon: "https://api.iconify.design/mdi:file-powerpoint-box.svg?color=%237f7f7f",
    kind: "Slide",
    label: "Slide",
    onClick: (app) => {
      let taskId: string;
      let url: string | undefined;
      if (app.room.region === "cn-hz") {
        taskId = "82d16c40b15745f0b5fad096ac721773";
      } else {
        taskId = "1bd92aa00e28413c8668cffdbc97116f";
        url = "https://convertcdn-sg.netless.link/dynamicConvert";
      }
      app.insertDocs({
        fileType: "pptx",
        scenePath: `/pptx/${taskId}`,
        taskId,
        title: "a.pptx",
        url,
      });
    },
  });

  let imageIndex = 0;
  // Image
  // Note: you have to implement file uploading and hosting by yourself
  apps.push({
    icon: "https://api.iconify.design/ic:baseline-image.svg?color=%237f7f7f",
    kind: "Image",
    label: "Image",
    onClick: (app) => {
      app.insertImage(`https://flat-storage-sg.oss-accelerate.aliyuncs.com/fastboard/${imageIndex}.webp`);
      imageIndex = (imageIndex + 1) % 3;
    },
  });

  // Media
  // Note: you have to implement file uploading and hosting by yourself
  apps.push({
    icon: "https://api.iconify.design/mdi:filmstrip-box.svg?color=%237f7f7f",
    kind: "Media",
    label: "Media",
    onClick: (app) => {
      app.insertMedia("a.mp4", "https://cdn.plyr.io/static/demo/View_From_A_Blue_Moon_Trailer-576p.mp4");
    },
  });

  // YouTube Video
  apps.push({
    icon: "https://api.iconify.design/logos:youtube-icon.svg?color=%237f7f7f",
    kind: "Plyr",
    label: "YouTube",
    onClick(app) {
      const url = window.prompt("Enter YouTube URL", "https://www.youtube.com/watch?v=bTqVqk7FSmY");
      if (!url) return;
      app.manager.addApp({
        kind: "Plyr",
        options: { title: "YouTube" },
        attributes: {
          src: url,
          provider: "youtube",
        },
      });
    },
  });

  // Google Docs
  apps.push({
    icon: "https://api.iconify.design/logos:google-icon.svg?color=%237f7f7f",
    kind: "EmbeddedPage",
    label: "Google Docs",
    onClick(app) {
      const url = window.prompt(
        "Enter Google Doc Share URL",
        "https://docs.google.com/document/d/1bd4SRb5BmTUjPGrFxU2V7KI2g_mQ-HQUBxKTxsEn5e4/edit?usp=sharing"
      );
      if (!url) return;
      app.manager.addApp({
        kind: "EmbeddedPage",
        options: { title: "Google Docs" },
        attributes: {
          src: url,
        },
      });
    },
  });

  register({
    kind: "PDFjs",
    src: "https://cdn.jsdelivr.net/npm/@netless/app-pdfjs@0.1.4",
    name: "NetlessAppPDFjs",
    appOptions: {
      pdfjsLib: 'https://cdn.jsdelivr.net/npm/pdfjs-dist@latest/build/pdf.min.mjs',
      workerSrc: 'https://cdn.jsdelivr.net/npm/pdfjs-dist@latest/build/pdf.worker.min.mjs'
    }
  });
  apps.push({
    icon: "https://api.iconify.design/mdi:file-pdf.svg?color=%237f7f7f",
    kind: "PDFjs",
    label: "PDF.js",
    async onClick(app) {
      app.manager.addApp({
        kind: "PDFjs",
        options: {
          title: "PDFjs",
          scenePath: `/pdfjs/e349fe51afb3493c893243789b467d6b`,
        },
        attributes: {
          prefix: "https://white-cover.oss-cn-hangzhou.aliyuncs.com/flat/", // ! Required.
          taskId: "e349fe51afb3493c893243789b467d6b", // ! Required.
        },
      });
    },
  });

  // quill
  register({
    kind: 'Quill',
    src: () => import("@netless/app-quill")
  })

  apps.push({
    icon: "https://api.iconify.design/hugeicons:quill-write-02.svg?color=%237f7f7f",
    kind: 'Quill',
    label: 'Quill',
    async onClick(app) {
      app.manager.addApp({
        kind: 'Quill',
      })
    },
  });

  // Save PDF (need jspdf)
  apps.push({
    icon: "https://api.iconify.design/material-symbols:download-rounded.svg?color=%237f7f7f",
    kind: "SavePDF",
    label: "Save PDF",
    onClick(app) {
      const slides = app.manager
        .queryAll()
        .filter((app) => app.kind === "Slide" || app.kind === "DocsViewer");
      if (slides.length === 0) {
        alert("No slide found, please add a slide first.");
        return;
      }
      const dialog = document.createElement("section");
      dialog.className = "dialog";
      const closeBtn = document.createElement("button");
      closeBtn.innerHTML = "&cross;";
      closeBtn.title = "close dialog";
      closeBtn.onclick = function closeDialog() {
        if (dialog.parentElement) dialog.remove();
      };
      const info = document.createElement("p");
      info.textContent = `Found ${slides.length} ${
        slides.length > 1 ? "slides" : "slide"
      }, please select the slide you want to export.`;
      info.appendChild(closeBtn);
      dialog.appendChild(info);
      const section = document.createElement("div");
      const select = document.createElement("select");
      slides.forEach((slide) => {
        const option = document.createElement("option");
        option.value = slide.id;
        option.textContent = (slide.box?.title || "Untitled") + " (" + slide.id + ")";
        select.appendChild(option);
      });
      section.appendChild(select);
      section.appendChild(document.createTextNode(" "));
      const btn = document.createElement("button");
      btn.textContent = "Start";
      section.appendChild(btn);
      section.appendChild(document.createElement("br"));
      const progress = document.createElement("progress");
      progress.max = 100;
      progress.value = 0;
      section.appendChild(progress);
      const status = document.createElement("span");
      section.appendChild(document.createTextNode(" "));
      section.appendChild(status);
      dialog.appendChild(section);
      const link = document.createElement("a");
      link.href = "https://github.com/netless-io/window-manager/blob/master/docs/export-pdf.md";
      link.target = "_blank";
      link.textContent = "API Doc";
      dialog.appendChild(link);
      btn.onclick = function savePDF() {
        btn.disabled = true;
        status.innerText = "0%";
        postMessage({
          type: "@netless/_request_save_pdf_",
          appId: select.value,
        });
        const handler = (ev: MessageEvent) => {
          if (ev.data.type === "@netless/_result_save_pdf_") {
            const value = (progress.value = ev.data.progress);
            status.innerText = value + "%";
            if (value === 100) {
              if (ev.data.result) {
                console.log(ev.data.result);
                const a = document.createElement("a");
                const buffer = ev.data.result.pdf as ArrayBuffer;
                a.href = URL.createObjectURL(new Blob([buffer], { type: "application/pdf" }));
                a.title = ev.data.result.title;
                a.download = "download.pdf";
                a.innerText = "Download";
                link.before(a);
              } else {
                status.innerText = "Failed";
              }
            }
          }
        };
        window.addEventListener("message", handler);
      };
      document.body.appendChild(dialog);
    },
  });

  // Save snapshot (since white-web-sdk 2.16.36)
  apps.push({
    icon: "https://api.iconify.design/mingcute:screenshot-line.svg?color=%237f7f7f",
    kind: "Snapshot",
    label: "Screenshot",
    async onClick(app) {
      const view = app.manager.mainView;
      const canvas = document.createElement("canvas");
      canvas.width = view.size.width;
      canvas.height = view.size.height;
      const c = canvas.getContext("2d");
      if (!c) {
        alert("canvas.getContext('2d') failed!");
        return;
      }
      if (app.appliancePlugin) {
        await app.appliancePlugin.screenshotToCanvasAsync(
          c,
          view.focusScenePath || "/init",
          view.size.width,
          view.size.height,
          {...view.camera}
        );
      } else {
        view.screenshotToCanvas(
          c,
          view.focusScenePath || "/init",
          view.size.width,
          view.size.height,
          view.camera
        );
      }
      try {
        canvas.toBlob((blob) => {
          if (!blob) {
            alert("context.toBlob() failed!");
            return;
          }
          const url = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "screenshot.png";
          a.click();
        });
      } catch (err) {
        const dialog = document.createElement("section");
        dialog.className = "dialog";
        const header = document.createElement("p");
        header.textContent =
          "This image contains CORS resources that cannot be exported to URL, you can right click and save this image manually.";
        const closeBtn = document.createElement("button");
        closeBtn.innerHTML = "&cross;";
        closeBtn.title = "close dialog";
        closeBtn.onclick = function closeDialog() {
          if (dialog.parentElement) dialog.remove();
        };
        header.appendChild(closeBtn);
        dialog.appendChild(header);
        dialog.appendChild(canvas);
        document.body.appendChild(dialog);
      }
    },
  });

  // save PNG
  apps.push({
    icon: "https://api.iconify.design/teenyicons:png-outline.svg?color=%237f7f7f",
    kind: "fillSceneshot",
    label: "FillSceneshot",

    async onClick(app) {
      const canvas = document.createElement("canvas");
      const c = canvas.getContext("2d");
      if (!c) {
        alert("canvas.getContext('2d') failed!");
        return;
      }
      const view = app.manager.mainView;
      const _width = view.size.width;
      const _height =  view.size.height;
      const scenePath = view.focusScenePath || "/init";
      let rect = view.getBoundingRect(scenePath);
      if (app.appliancePlugin) {
        rect = await app.appliancePlugin.getBoundingRectAsync(scenePath) || rect;
      }
      if (!rect) {
        rect = {
            originX: 0,
            originY: 0,
            width: _width,
            height: _height,
        }
      }
      let w = Math.max(rect?.width, _width);
      let h = Math.max(rect?.height, _height);
      let scale:number = 1;
      const scaleW = w > _width && Math.min(_width / w, scale) || scale;
      const scaleH = h > _height && Math.min(_height / h, scale) || scale;
      if (scaleW <= scaleH) {
          w = scaleW < 1 && _width || w;
          h = Math.floor(h * scaleW) + 1;
          scale = scaleW;
      } else if (scaleW > scaleH) {
          h = scaleH < 1 && _height || h;
          w = Math.floor(w * scaleH) + 1;
          scale = scaleH;
      }
      const centerCamera = {
          scale,
          centerX: rect.originX + rect.width / 2,
          centerY: rect.originY + rect.height / 2,
      }
      canvas.width = w;
      canvas.height = h;
      if (app.appliancePlugin) {
        await app.appliancePlugin.screenshotToCanvasAsync(
          c,
          scenePath,
          w,
          h,
          centerCamera
        )
      } else {
        view.screenshotToCanvas(
          c,
          scenePath,
          w,
          h,
          centerCamera
        );
      }
      try {
        canvas.toBlob((blob) => {
          if (!blob) {
            alert("context.toBlob() failed!");
            return;
          }
          const url = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "screenshot.png";
          a.click();
        });
      } catch (err) {
        const dialog = document.createElement("section");
        dialog.className = "dialog";
        const header = document.createElement("p");
        header.textContent =
          "This image contains CORS resources that cannot be exported to URL, you can right click and save this image manually.";
        const closeBtn = document.createElement("button");
        closeBtn.innerHTML = "&cross;";
        closeBtn.title = "close dialog";
        closeBtn.onclick = function closeDialog() {
          if (dialog.parentElement) dialog.remove();
        };
        header.appendChild(closeBtn);
        dialog.appendChild(header);
        dialog.appendChild(canvas);
        document.body.appendChild(dialog);
      }
    },
  });

  // Export scene
  // apps.push({
  //   icon: "https://api.iconify.design/mdi:file-export-outline.svg?color=%237f7f7f",
  //   kind: "Export",
  //   label: "Export",
  //   async onClick(app) {
  //     const { scenePath } = app.manager.sceneState;
  //     console.log("export scene", scenePath);
  //     const blob = await app.room.exportScene(scenePath);
  //     const url = URL.createObjectURL(blob);

  //     const a = document.createElement("a");
  //     a.href = url;
  //     a.title = scenePath;
  //     a.download = `whiteboard${scenePath.replace(/\//g, "-")}.scene`;
  //     a.style.display = "none";
  //     document.body.appendChild(a);
  //     a.click();

  //     setTimeout(() => {
  //       a.remove();
  //       URL.revokeObjectURL(url);
  //       alert("Drop the 'whietboard-*.scene' file to import it.");
  //     }, 1000);
  //   },
  // });
}
